import LS, { LS_KEY } from './LocalStorage';
/**
 * This module provides theme configuration and a function to apply themes to the application.
 *
 * `themesStylesConfig`:
 * An object containing the theme configurations for light and dark themes. Each theme is defined as an object with CSS variable names and their corresponding values.
 *
 * Example usage:
 * const theme = themesStylesConfig.lightTheme;
 * console.log(theme.typographyPrimary); // { name: '--typography-primary', value: '#171B27' }
 *
 * `setTheme(theme)`:
 * A function to apply the specified theme to the application. It takes a theme name (either 'LIGHT' or 'DARK') and sets the CSS variables on the root element accordingly.
 *
 * Example usage:
 * setTheme(THEME_VALUES.LIGHT); // Applies the light theme
 * setTheme(THEME_VALUES.DARK);  // Applies the dark theme
 */
export const THEME_VALUES = {
    LIGHT: 'LIGHT',
    DARK: 'DARK'
};
// eslint-disable-next-line import/prefer-default-export
export const themesStylesConfig = {
    lightTheme: {
        black: { name: '--black', value: '#171B27' },
        white: { name: '--white', value: '#FFFFFF' },
        typographyPrimary: { name: '--typography-primary', value: '#171B27' },
        typographySecondary: { name: '--typography-secondary', value: '#64676E' },
        typographyDisabled: { name: '--typography-disabled', value: '#CACCCE' },
        typographyLink: { name: '--typography-link', value: '#2461FF' },
        typographyLinkHover: { name: '--typography-link-hover', value: '#2954C2' },
        surfacePrimary: { name: '--surface-primary', value: '#FFFFFF' },
        surfacePrimaryHover: { name: '--surface-primary-hover', value: '#FFFFFF' },
        surfaceSecondary: { name: '--surface-secondary', value: '#F4F4F6' },
        surfaceRow: { name: '--surface-row', value: '#F7F7F8' },
        surfaceElevated: { name: '--surface-elevated', value: '#171B27' },
        effectsShadowElevatedBlur: { name: '--effects-shadow-elevated-blur', value: '16px' },
        effectsShadowElevatedSpread: { name: '--effects-shadow-elevated-spread', value: '0' },
        effectsShadowElevatedColor: { name: '--effects-shadow-elevated-color', value: '#0000001F' },
        surfaceOverlay: { name: '--surface-overlay', value: '#14161D' },
        semanticInfoPrimary400: { name: '--semantic-info-primary-400', value: '#2F69FF' },
        semanticNeutral50: { name: '--semantic-neutral-50', value: '#FFFFFF' },
        semanticNeutral100: { name: '--semantic-neutral-100', value: '#F5F5F5' },
        semanticNeutral150: { name: '--semantic-neutral-150', value: '#E2E3E4' },
        semanticNeutral200: { name: '--semantic-neutral-200', value: '#CACCCE' },
        semanticNeutral250: { name: '--semantic-neutral-250', value: '#94979E' },
        semanticNeutral300: { name: '--semantic-neutral-300', value: '#64676E' },
        semanticNeutral350: { name: '--semantic-neutral-350', value: '#575B65' },
        semanticNeutral400: { name: '--semantic-neutral-400', value: '#4E525B' },
        semanticNeutral450: { name: '--semantic-neutral-450', value: '#3D4047' },
        semanticNeutral500: { name: '--semantic-neutral-500', value: '#181D29' },
        semanticInfo50: { name: '--semantic-info-50', value: '#F0F5FF' },
        semanticInfo100: { name: '--semantic-info-100', value: '#EAF0FF' },
        semanticInfo150: { name: '--semantic-info-150', value: '#C1D2FF' },
        semanticInfo200: { name: '--semantic-info-200', value: '#97B4FF' },
        semanticInfo250: { name: '--semantic-info-250', value: '#82A5FF' },
        semanticInfo300: { name: '--semantic-info-300', value: '#6D96FF' },
        semanticInfo350: { name: '--semantic-info-350', value: '#5887FF' },
        semanticInfo400: { name: '--semantic-info-400', value: '#2461FF' },
        semanticInfo450: { name: '--semantic-info-450', value: '#2954C2' },
        semanticInfo500: { name: '--semantic-info-500', value: '#22386F' },
        semanticSuccess50: { name: '--semantic-success-50', value: '#E4F9F6' },
        semanticSuccess100: { name: '--semantic-success-100', value: '#BCEFE9' },
        semanticSuccess150: { name: '--semantic-success-150', value: '#8FE4DA' },
        semanticSuccess200: { name: '--semantic-success-200', value: '#79DFD3' },
        semanticSuccess250: { name: '--semantic-success-250', value: '#63DACB' },
        semanticSuccess300: { name: '--semantic-success-300', value: '#4DD5C4' },
        semanticSuccess350: { name: '--semantic-success-350', value: '#20CAB5' },
        semanticSuccess400: { name: '--semantic-success-400', value: '#20A99B' },
        semanticSuccess450: { name: '--semantic-success-450', value: '#1E7673' },
        semanticSuccess500: { name: '--semantic-success-500', value: '#1E5558' },
        semanticDanger50: { name: '--semantic-danger-50', value: '#FBE9ED' },
        semanticDanger100: { name: '--semantic-danger-100', value: '#F5C7D2' },
        semanticDanger150: { name: '--semantic-danger-150', value: '#EB8FA5' },
        semanticDanger200: { name: '--semantic-danger-200', value: '#EB8FA5' },
        semanticDanger250: { name: '--semantic-danger-250', value: '#E87C96' },
        semanticDanger300: { name: '--semantic-danger-300', value: '#E56A87' },
        semanticDanger350: { name: '--semantic-danger-350', value: '#DE446A' },
        semanticDanger400: { name: '--semantic-danger-400', value: '#B83E5E' },
        semanticDanger450: { name: '--semantic-danger-450', value: '#903753' },
        semanticDanger500: { name: '--semantic-danger-500', value: '#7D334D' },
        semanticCaution50: { name: '--semantic-caution-50', value: '#FBF8E6' },
        semanticCaution100: { name: '--semantic-caution-100', value: '#FAF7E3' },
        semanticCaution150: { name: '--semantic-caution-150', value: '#F5EEC7' },
        semanticCaution200: { name: '--semantic-caution-200', value: '#EBDC8F' },
        semanticCaution250: { name: '--semantic-caution-250', value: '#EBDC8F' },
        semanticCaution300: { name: '--semantic-caution-300', value: '#E8D77C' },
        semanticCaution350: { name: '--semantic-caution-350', value: '#E5D16A' },
        semanticCaution400: { name: '--semantic-caution-400', value: '#DEC544' },
        semanticCaution450: { name: '--semantic-caution-450', value: '#847833' },
        semanticCaution500: { name: '--semantic-caution-500', value: '#575337' },
        semanticDiscovery50: { name: '--semantic-discovery-50', value: '#F6F4FD' },
        semanticDiscovery100: { name: '--semantic-discovery-100', value: '#EDEAFB' },
        semanticDiscovery150: { name: '--semantic-discovery-150', value: '#CABFF4' },
        semanticDiscovery200: { name: '--semantic-discovery-200', value: '#947FE8' },
        semanticDiscovery250: { name: '--semantic-discovery-250', value: '#947FE8' },
        semanticDiscovery300: { name: '--semantic-discovery-300', value: '#8269E4' },
        semanticDiscovery350: { name: '--semantic-discovery-350', value: '#7154E1' },
        semanticDiscovery400: { name: '--semantic-discovery-400', value: '#4D29D9' },
        semanticDiscovery450: { name: '--semantic-discovery-450', value: '#392796' },
        semanticDiscovery500: { name: '--semantic-discovery-500', value: '#2B2463' },
        semanticWarning50: { name: '--semantic-warning-50', value: '#FCF2EC' },
        semanticWarning100: { name: '--semantic-warning-100', value: '#F9E8DD' },
        semanticWarning150: { name: '--semantic-warning-150', value: '#F5D8C7' },
        semanticWarning200: { name: '--semantic-warning-200', value: '#EBB08F' },
        semanticWarning250: { name: '--semantic-warning-250', value: '#EBB08F' },
        semanticWarning300: { name: '--semantic-warning-300', value: '#E8A37C' },
        semanticWarning350: { name: '--semantic-warning-350', value: '#E5966A' },
        semanticWarning400: { name: '--semantic-warning-400', value: '#DE7C44' },
        semanticWarning450: { name: '--semantic-warning-450', value: '#90583D' },
        semanticWarning500: { name: '--semantic-warning-500', value: '#573D37' },
        categoricalChart1: { name: '--categorical-chart-1', value: '#6347D3' }, // Categorical chart 1
        categoricalChart2: { name: '--categorical-chart-2', value: '#42A4F3' },
        categoricalChart3: { name: '--categorical-chart-3', value: '#20CAB5' }, // Categorical chart 3
        categoricalChart4: { name: '--categorical-chart-4', value: '#F4AD37' },
        categoricalChart5: { name: '--categorical-chart-5', value: '#F1BFF4' },
        categoricalChart6: { name: '--categorical-chart-6', value: '#DE446A' },
        categoricalChart7: { name: '--categorical-chart-7', value: '#4B2F5D' },
        categoricalChart8: { name: '--categorical-chart-8', value: '#AE4900' },
        categoricalPurple100: { name: '--categorical-purple-100', value: '#EADCFF' },
        categoricalPurple200: { name: '--categorical-purple-200', value: '#D2BDF7' },
        categoricalPurple300: { name: '--categorical-purple-300', value: '#BA9EEE' },
        categoricalPurple400: { name: '--categorical-purple-400', value: '#A081E5' },
        categoricalPurple500: { name: '--categorical-purple-500', value: '#8364DC' },
        categoricalPurple600: { name: '--categorical-purple-600', value: '#6347D3' }, // Categorical chart 1
        categoricalBlue100: { name: '--categorical-blue-100', value: '#DDECFF' },
        categoricalBlue200: { name: '--categorical-blue-200', value: '#C3DDFD' },
        categoricalBlue300: { name: '--categorical-blue-300', value: '#A9CFFB' },
        categoricalBlue400: { name: '--categorical-blue-400', value: '#8CC0F8' },
        categoricalBlue500: { name: '--categorical-blue-500', value: '#6CB2F6' },
        categoricalBlue600: { name: '--categorical-blue-600', value: '#42A4F3' },
        categoricalGreen100: { name: '--categorical-green-100', value: '#D1F2EB' },
        categoricalGreen200: { name: '--categorical-green-200', value: '#B7EAE0' },
        categoricalGreen300: { name: '--categorical-green-300', value: '#9BE3D5' },
        categoricalGreen400: { name: '--categorical-green-400', value: '#7CDBCA' },
        categoricalGreen500: { name: '--categorical-green-500', value: '#59D2C0' },
        categoricalGreen600: { name: '--categorical-green-600', value: '#20CAB5' }, // Categorical chart 3
        categoricalOrange100: { name: '--categorical-orange-100', value: '#FAE8D5' },
        categoricalOrange200: { name: '--categorical-orange-200', value: '#FCDCB7' },
        categoricalOrange300: { name: '--categorical-orange-300', value: '#FDD098' },
        categoricalOrange400: { name: '--categorical-orange-400', value: '#F8B85A' },
        categoricalOrange500: { name: '--categorical-orange-500', value: '#F8B85A' },
        categoricalOrange600: { name: '--categorical-orange-600', value: '#F4AD37' },
        categoricalPink100: { name: '--categorical-pink-100', value: '#F8E5F9' },
        categoricalPink200: { name: '--categorical-pink-200', value: '#F7DDF8' },
        categoricalPink300: { name: '--categorical-pink-300', value: '#F5D6F7' },
        categoricalPink400: { name: '--categorical-pink-400', value: '#F4CEF6' },
        categoricalPink500: { name: '--categorical-pink-500', value: '#F3C7F5' },
        categoricalPink600: { name: '--categorical-pink-600', value: '#F1BFF4' },
        categoricalRed100: { name: '--categorical-red-100', value: '#FFE3E7' },
        categoricalRed200: { name: '--categorical-red-200', value: '#FCC5CD' },
        categoricalRed300: { name: '--categorical-red-300', value: '#F7A7B4' },
        categoricalRed400: { name: '--categorical-red-400', value: '#F0899B' },
        categoricalRed500: { name: '--categorical-red-500', value: '#E86982' },
        categoricalRed600: { name: '--categorical-red-600', value: '#DE446A' },
        categoricalViolet100: { name: '--categorical-violet-100', value: '#EDEAEF' },
        categoricalViolet200: { name: '--categorical-violet-200', value: '#DBD5DF' },
        categoricalViolet300: { name: '--categorical-violet-300', value: '#B7ACBE' },
        categoricalViolet400: { name: '--categorical-violet-400', value: '#93829E' },
        categoricalViolet500: { name: '--categorical-violet-500', value: '#6F597D' },
        categoricalViolet600: { name: '--categorical-violet-600', value: '#4B2F5D' },
        categoricalBrown100: { name: '--categorical-brown-100', value: '#F7EDE5' },
        categoricalBrown200: { name: '--categorical-brown-200', value: '#EFDBCC' },
        categoricalBrown300: { name: '--categorical-brown-300', value: '#DFB699' },
        categoricalBrown400: { name: '--categorical-brown-400', value: '#CE9266' },
        categoricalBrown500: { name: '--categorical-brown-500', value: '#BE6D33' },
        categoricalBrown600: { name: '--categorical-brown-600', value: '#AE4900' }
    },
    darkTheme: {
        black: { name: '--black', value: '#171B27' },
        white: { name: '--white', value: '#FFFFFF' },
        typographyPrimary: { name: '--typography-primary', value: '#e8e9eA' },
        typographySecondary: { name: '--typography-secondary', value: '#aeb1B6' },
        typographyDisabled: { name: '--typography-disabled', value: '#5c606B' },
        typographyLink: { name: '--typography-link', value: '#6d96ff' },
        typographyLinkHover: { name: '--typography-link-hover', value: '#99B6FF' },
        surfacePrimary: { name: '--surface-primary', value: '#1c2231' },
        surfacePrimaryHover: { name: '--surface-primary-hover', value: '#202637' },
        surfaceSecondary: { name: '--surface-secondary', value: '#151821' },
        surfaceRow: { name: '--surface-row', value: '#181e2a' },
        surfaceElevated: { name: '--surface-elevated', value: '#333846' },
        effectsShadowElevatedBlur: { name: '--effects-shadow-elevated-blur', value: '24px' },
        effectsShadowElevatedSpread: { name: '--effects-shadow-elevated-spread', value: '8px' },
        effectsShadowElevatedColor: { name: '--effects-shadow-elevated-color', value: '#00000040' },
        surfaceOverlay: { name: '--surface-overlay', value: '#14161d' },
        semanticInfo: { name: '--semantic-info', value: '#82a5ff' },
        semanticDanger: { name: '--semantic-danger', value: '#e56a87' },
        semanticWarning: { name: '--semantic-warning', value: '#e5966a' },
        semanticCaution: { name: '--semantic-caution', value: '#e8d77c' },
        semanticSuccess: { name: '--semantic-success', value: '#20cab5' },
        semanticNeutral: { name: '--semantic-neutral', value: '#d2d4d6' },
        semanticDiscovery: { name: '--semantic-discovery', value: '#9a86e9' },
        semanticNeutral50: { name: '--semantic-neutral-50', value: '#181d29' },
        semanticNeutral100: { name: '--semantic-neutral-100', value: '#202637' },
        semanticNeutral150: { name: '--semantic-neutral-150', value: '#333846' },
        semanticNeutral200: { name: '--semantic-neutral-200', value: '#5c606B' },
        semanticNeutral250: { name: '--semantic-neutral-250', value: '#9da0A6' },
        semanticNeutral300: { name: '--semantic-neutral-300', value: '#aeb1B6' },
        semanticNeutral350: { name: '--semantic-neutral-350', value: '#bec1C6' },
        semanticNeutral400: { name: '--semantic-neutral-400', value: '#cccFD4' },
        semanticNeutral450: { name: '--semantic-neutral-450', value: '#d2d4D6' },
        semanticNeutral500: { name: '--semantic-neutral-500', value: '#e2e3E4' },
        semanticInfo50: { name: '--semantic-info-50', value: '#1d2538' },
        semanticInfo100: { name: '--semantic-info-100', value: '#1e2a45' },
        semanticInfo150: { name: '--semantic-info-150', value: '#22386f' },
        semanticInfo200: { name: '--semantic-info-200', value: '#254698' },
        semanticInfo250: { name: '--semantic-info-250', value: '#274dad' },
        semanticInfo300: { name: '--semantic-info-300', value: '#2954c2' },
        semanticInfo350: { name: '--semantic-info-350', value: '#2b5bd6' },
        semanticInfo400: { name: '--semantic-info-400', value: '#82a5ff' },
        semanticInfo450: { name: '--semantic-info-450', value: '#c1d2ff' },
        semanticInfo500: { name: '--semantic-info-500', value: '#eaf0ff' },
        semanticSuccess50: { name: '--semantic-success-50', value: '#1c2835' },
        semanticSuccess100: { name: '--semantic-success-100', value: '#1c2B37' },
        semanticSuccess150: { name: '--semantic-success-150', value: '#1c2E3A' },
        semanticSuccess200: { name: '--semantic-success-200', value: '#1c333E' },
        semanticSuccess250: { name: '--semantic-success-250', value: '#1e5558' },
        semanticSuccess300: { name: '--semantic-success-300', value: '#1e7673' },
        semanticSuccess350: { name: '--semantic-success-350', value: '#1e8781' },
        semanticSuccess400: { name: '--semantic-success-400', value: '#1e978E' },
        semanticSuccess450: { name: '--semantic-success-450', value: '#20cAB5' },
        semanticSuccess500: { name: '--semantic-success-500', value: '#79dFD3' },
        semanticDanger50: { name: '--semantic-danger-50', value: '#252434' },
        semanticDanger100: { name: '--semantic-danger-100', value: '#262434' },
        semanticDanger150: { name: '--semantic-danger-150', value: '#292536' },
        semanticDanger200: { name: '--semantic-danger-200', value: '#2f2637' },
        semanticDanger250: { name: '--semantic-danger-250', value: '#572d42' },
        semanticDanger300: { name: '--semantic-danger-300', value: '#7d334D' },
        semanticDanger350: { name: '--semantic-danger-350', value: '#903753' },
        semanticDanger400: { name: '--semantic-danger-400', value: '#a33a59' },
        semanticDanger450: { name: '--semantic-danger-450', value: '#e56a87' },
        semanticDanger500: { name: '--semantic-danger-500', value: '#eb8fA5' },
        semanticCaution50: { name: '--semantic-caution-50', value: '#2c3033' },
        semanticCaution100: { name: '--semantic-caution-100', value: '#2f3333' },
        semanticCaution150: { name: '--semantic-caution-150', value: '#575337' },
        semanticCaution200: { name: '--semantic-caution-200', value: '#7d743A' },
        semanticCaution250: { name: '--semantic-caution-250', value: '#90843D' },
        semanticCaution300: { name: '--semantic-caution-300', value: '#a3943F' },
        semanticCaution350: { name: '--semantic-caution-350', value: '#b8a541' },
        semanticCaution400: { name: '--semantic-caution-400', value: '#dec544' },
        semanticCaution450: { name: '--semantic-caution-450', value: '#e8d77C' },
        semanticCaution500: { name: '--semantic-caution-500', value: '#f5eEC7' },
        semanticDiscovery50: { name: '--semantic-discovery-50', value: '#212342' },
        semanticDiscovery100: { name: '--semantic-discovery-100', value: '#262354' },
        semanticDiscovery150: { name: '--semantic-discovery-150', value: '#2B2463' },
        semanticDiscovery200: { name: '--semantic-discovery-200', value: '#352685' },
        semanticDiscovery250: { name: '--semantic-discovery-250', value: '#392796' },
        semanticDiscovery300: { name: '--semantic-discovery-300', value: '#3E27A7' },
        semanticDiscovery350: { name: '--semantic-discovery-350', value: '#4428B8' },
        semanticDiscovery400: { name: '--semantic-discovery-400', value: '#4D29D9' },
        semanticDiscovery450: { name: '--semantic-discovery-450', value: '#9A86E9' },
        semanticDiscovery500: { name: '--semantic-discovery-500', value: '#cABFF4' },
        semanticWarning50: { name: '--semantic-warning-50', value: '#282832' },
        semanticWarning100: { name: '--semantic-warning-100', value: '#2d2A33' },
        semanticWarning150: { name: '--semantic-warning-150', value: '#3a3034' },
        semanticWarning200: { name: '--semantic-warning-200', value: '#563D37' },
        semanticWarning250: { name: '--semantic-warning-250', value: '#85523C' },
        semanticWarning300: { name: '--semantic-warning-300', value: '#a3613F' },
        semanticWarning350: { name: '--semantic-warning-350', value: '#b86A41' },
        semanticWarning400: { name: '--semantic-warning-400', value: '#de7C44' },
        semanticWarning450: { name: '--semantic-warning-450', value: '#e5966A' },
        semanticWarning500: { name: '--semantic-warning-500', value: '#f5d8C7' },
        categoricalChart1: { name: '--categorical-chart-1', value: '#6a53C7' },
        categoricalChart2: { name: '--categorical-chart-2', value: '#50a2E5' },
        categoricalChart3: { name: '--categorical-chart-3', value: '#37b3A4' },
        categoricalChart4: { name: '--categorical-chart-4', value: '#dfa84C' },
        categoricalChart5: { name: '--categorical-chart-5', value: '#703475' },
        categoricalChart6: { name: '--categorical-chart-6', value: '#d34f6F' },
        categoricalChart7: { name: '--categorical-chart-7', value: '#cba9DF' },
        categoricalChart8: { name: '--categorical-chart-8', value: '#944d1A' },
        categoricalPurple100: { name: '--categorical-purple-100', value: '#242740' },
        categoricalPurple200: { name: '--categorical-purple-200', value: '#2B2C4F' },
        categoricalPurple300: { name: '--categorical-purple-300', value: '#3B356D' },
        categoricalPurple400: { name: '--categorical-purple-400', value: '#4B408B' },
        categoricalPurple500: { name: '--categorical-purple-500', value: '#5B49A9' },
        categoricalPurple600: { name: '--categorical-purple-600', value: '#6A53C7' },
        categoricalBlue100: { name: '--categorical-blue-100', value: '#212F43' },
        categoricalBlue200: { name: '--categorical-blue-200', value: '#263B55' },
        categoricalBlue300: { name: '--categorical-blue-300', value: '#315579' },
        categoricalBlue400: { name: '--categorical-blue-400', value: '#3b6F9D' },
        categoricalBlue500: { name: '--categorical-blue-500', value: '#4689C1' },
        categoricalBlue600: { name: '--categorical-blue-600', value: '#50a2E5' },
        categoricalGreen100: { name: '--categorical-green-100', value: '#1f313C' },
        categoricalGreen200: { name: '--categorical-green-200', value: '#213F48' },
        categoricalGreen300: { name: '--categorical-green-300', value: '#275C5F' },
        categoricalGreen400: { name: '--categorical-green-400', value: '#2c7976' },
        categoricalGreen500: { name: '--categorical-green-500', value: '#32968D' },
        categoricalGreen600: { name: '--categorical-green-600', value: '#37B3A4' },
        categoricalOrange100: { name: '--categorical-orange-100', value: '#2F3034' },
        categoricalOrange200: { name: '--categorical-orange-200', value: '#433D36' },
        categoricalOrange300: { name: '--categorical-orange-300', value: '#6A573B' },
        categoricalOrange400: { name: '--categorical-orange-400', value: '#917342' },
        categoricalOrange500: { name: '--categorical-orange-500', value: '#b88D47' },
        categoricalOrange600: { name: '--categorical-orange-600', value: '#dFA84C' },
        categoricalPink100: { name: '--categorical-pink-100', value: '#242438' },
        categoricalPink200: { name: '--categorical-pink-200', value: '#2c253E' },
        categoricalPink300: { name: '--categorical-pink-300', value: '#3e294C' },
        categoricalPink400: { name: '--categorical-pink-400', value: '#4e2D5A' },
        categoricalPink500: { name: '--categorical-pink-500', value: '#603168' },
        categoricalPink600: { name: '--categorical-pink-600', value: '#703475' },
        categoricalRed100: { name: '--categorical-red-100', value: '#2e2737' },
        categoricalRed200: { name: '--categorical-red-200', value: '#402b3D' },
        categoricalRed300: { name: '--categorical-red-300', value: '#653449' },
        categoricalRed400: { name: '--categorical-red-400', value: '#8a3d57' },
        categoricalRed500: { name: '--categorical-red-500', value: '#af4663' },
        categoricalRed600: { name: '--categorical-red-600', value: '#d34f6F' },
        categoricalViolet100: { name: '--categorical-violet-100', value: '#2D3042' },
        categoricalViolet200: { name: '--categorical-violet-200', value: '#3F3D54' },
        categoricalViolet300: { name: '--categorical-violet-300', value: '#625876' },
        categoricalViolet400: { name: '--categorical-violet-400', value: '#85739A' },
        categoricalViolet500: { name: '--categorical-violet-500', value: '#a88EBC' },
        categoricalViolet600: { name: '--categorical-violet-600', value: '#cBA9DF' },
        categoricalBrown100: { name: '--categorical-brown-100', value: '#28272F' },
        categoricalBrown200: { name: '--categorical-brown-200', value: '#342A2C' },
        categoricalBrown300: { name: '--categorical-brown-300', value: '#4c3327' },
        categoricalBrown400: { name: '--categorical-brown-400', value: '#643C24' },
        categoricalBrown500: { name: '--categorical-brown-500', value: '#7c451F' },
        categoricalBrown600: { name: '--categorical-brown-600', value: '#944D1A' }
    }
};

export function setTheme(theme) {
    const root = document.documentElement;
    let themeObj = themesStylesConfig.lightTheme;
    if (theme === THEME_VALUES.DARK) {
        themeObj = themesStylesConfig.darkTheme;
    }
    Object.values(themeObj).forEach((item) => {
        root.style.setProperty(item.name, item.value);
    });
}
export const getStyle = (theme) => {
    return Object.values(theme === THEME_VALUES.DARK
        ? themesStylesConfig.darkTheme
        : themesStylesConfig.lightTheme
    ).reduce((acc, { name, value }) => {
        acc[name] = value;
        return acc;
    }, {});
};


/**
 * Retrieves the theme value based on the provided parameters.
 *
 * @param {boolean} useLocalStorage - Indicates whether to use local storage to retrieve the theme value.
 * @param {string|null} theme - The theme value to be validated and used. Can be 'LIGHT', 'DARK', or null.
 * @returns {string} - The validated theme value. Defaults to 'LIGHT' if no valid theme is found.
 *
 * The function follows these steps to determine the theme value:
 * 1. Checks the provided theme parameter.
 * 2. Checks the URL parameter for a theme value.
 * 3. Checks the local storage for a theme value if useLocalStorage is true.
 * If none of these provide a valid theme, it defaults to 'LIGHT'.
 */
export function getThemeValue(useLocalStorage, theme) {
    const valid = (t) => (t && t.trim() &&
        (t.toUpperCase() === THEME_VALUES.DARK || t.toUpperCase() === THEME_VALUES.LIGHT) ?
        t.toUpperCase() : null);
    const retVal = valid(theme) ||
        valid(new URLSearchParams(window.location.search).get('theme')) ||
        valid(useLocalStorage ? LS.get(LS_KEY.themeColorScheme, THEME_VALUES.LIGHT) : null) ||
        THEME_VALUES.LIGHT;
    if (useLocalStorage) {
        LS.set(LS_KEY.themeColorScheme, retVal);
    }
    return retVal;
}
